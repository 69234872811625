import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import ContactForm from "../pages/contact/form";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import StockGainerPersonal from "../components/StockGainerPersonal";
import ChartTwo from "../components/ChartTwo";
import ChartBandLight from "../components/ChartBandLight";
import ChartThreeLight from "../components/ChartThreeLight";

export const ComplaintsSafeguardingPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
}) => (
  <div>
    <Helmet>
      <title>Swiftfox | Complaints & Safeguarding</title>
    </Helmet>
    <div className="hero-section faq-hero">
      <div className="hero-main">
        <div className="hero-main-content">
          <div className="section-item white-text">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h1
                  ref={ref}
                  initial={{ opacity: 0 }}
                  animate={inView ? { opacity: 1 } : { opacity: 1 }}
                  transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }}
                >
                  Complaints & Safeguarding
                </motion.h1>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4
                  ref={ref}
                  initial={{ opacity: 0 }}
                  animate={inView ? { opacity: 1 } : { opacity: 1 }}
                  transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}
                >
                  At Swiftfox, we strive to deliver excellent service, but we
                  understand that sometimes things may not go as planned. This
                  page outlines our complaints handling procedure and what you
                  can expect if you feel dissatisfied with our services.{" "}
                </motion.h4>
              )}
            </InView>
          </div>
        </div>
      </div>
    </div>

    <div className="callout-section white-bg dark-text">
      <div className="callout-main">
        <div className="callout-main-content stack-items underlinehyperlink">
          {" "}
          <p>
            <h3> Complaints</h3>
            <br></br>
            We are your first port of call for any queries or concerns,
            including complaints. We will handle these complaints in line with
            our complaints process.
            <br></br>
            <br></br>
            When making a complaint, please outline the following information
            <ul>
              <li>The date of the complaint</li>
              <li>The nature of your complaint</li>
              <li>The impact on your business</li>
              <li>Your contact details</li>
              <li>Any additional information</li>
            </ul>
            <br></br>
            How do I complain?
            <br></br>
            You can make a complaint by:
            <ul>
              <li>
                Speak to a member of the team on the phone: +44 (0)203 751 7522
              </li>
              <li>Email: support@swiftfox.co.uk</li>
            </ul>
            <br></br>
            What happens when a complain is made?
            <br></br>A member of the team will confirm receipt of your complaint
            via email within 24 hours of receiving it and will aim to resolve
            the complaint within three business days. On occasion, a complaint
            may need to be escalated and so the team may extend the timeline to
            resolve the complaint by the end of 15 business days following
            receipt of the complaint.
            <br></br>
            <br></br>
            In exceptional circumstances, where we are unable to issue a final
            response within 15 business days of receipt of the complaint, we
            have up to a maximum 35 business days from the date of receipt to
            issue a final response.
            <br></br>
            <br></br>
            If a customer still wants to escalate the complaint further,
            information will be provided on how to contact the Financial
            Ombudsman Service.
            <br></br>
            <br></br>
            We work with e-money and payments institution partners in providing
            you with our service, namelyCurrencycloud.Currencycloudultimately
            provides you with Regulated Services, i.e. geographically or
            functionally limited elements of regulated payments and e-money
            services in UK/EU/US. Currencycloud have certain obligations as
            regulated financial services institutions, including around
            complaints. We keep them informed of the complaints we receive from
            you regarding the regulated payments and e-money services they
            ultimately provide to you. They oversee how we handle complaints to
            ensure we do this to the standard required under the regulations.
            However, if for any reason your complaint regarding your payments
            and e-money services has not been acknowledged or dealt with by us,
            or if you have concerns about theway,it has been
            handled,Currencycloud's complaints information can be found{" "}
            <a
              href="https://www.currencycloud.com/legal/complaints-procedure/"
              target="_blank"
            >
              here
            </a>
            <br></br>
            <br></br>
            What if I am unhappy with the resolution? If, for whatever reason,
            you are unhappy with our response, you can request further
            assistance from the relevant government body. If you are resident in
            the UK you can contact the Financial Ombudsman Service (FOS) either
            by mail, using an online form or telephone. Further details and how
            to contact FOS can be found{" "}
            <a href="http://www.financial-ombudsman.org.uk/" target="_blank">
              {" "}
              here
            </a>
            <br></br>
            <br></br>
            If you are resident in the EU you can contact the Netherlands
            Financial Services Complaints Institute (Kifid) either by mail,
            using an online form or telephone. Further details and how to
            contact Kifid can be found{" "}
            <a href="https://www.kifid.nl" target="_blank">
              here
            </a>
            <br></br>
            <br></br>
          </p>
        </div>
      </div>
    </div>

    <div className="callout-section grey-bg dark-text">
      <div className="callout-main">
        <div className="callout-main-content stack-items">
          {" "}
          <p>
            <h3> Safeguarding </h3>
            <br></br>
            When funds are posted to your account, e-money is issued in exchange for these funds, by an Electronic Money Institution who we work with, called Currencycloud. In line with regulatory requirements, Currencycloud safeguards your funds. This means that the money behind the balance you see in your account is held at a reputable bank, and most importantly, is protected for you in the event of Currencycloud’s, or our, insolvency. Currencycloud stops safeguarding your funds when the money has been paid out of your account to your beneficiary's account.          </p>
        </div>
      </div>
    </div>
  </div>
);

ComplaintsSafeguardingPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const ComplaintsSafeguardingPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <ComplaintsSafeguardingPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

ComplaintsSafeguardingPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ComplaintsSafeguardingPage;

export const pageQuery = graphql`
  query ComplaintsSafeguardingPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`;
